import React from "react";
import { connect } from "react-redux";

import {
  LoginContainer,
  mapStateToProps,
  mapDispatchToProps
} from "components/Login/LoginContainer";

import LoginPage from "custom-components/Login/LoginPage";

class SingpostLoginContainer extends LoginContainer {
  render() {
    return (
      <LoginPage
        email={this.state.email}
        password={this.state.password}
        handleEmailChange={this.handleEmailChange}
        handlePasswordChange={this.handlePasswordChange}
        handleUpdateMarketingConsent={this.handleUpdateMarketingConsent}
        handleUpdatePrivacyAgreed={this.handleUpdatePrivacyAgreed}
        handleSubmit={this.handleSubmit}
        handlePrivacySubmit={this.handlePrivacySubmit}
        showPrivacyAgreement={this.state.showPrivacyAgreement}
        marketingConsent={this.state.marketingConsent}
        privacyAgreed={this.state.privacyAgreed}
        projectId={this.props.projectId}
        topbarlessBackButtonRoute={this.getTopbarlessBackButtonRoute()}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* Recaptcha */
        userRecaptchaActivate={this.state.userRecaptchaActivate}
        handleRecaptchaError={this.handleRecaptchaError}
        handleRecaptchaExpired={this.handleRecaptchaExpired}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseMicrosoft={this.responseMicrosoft}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
        /* loading indicators */
        isSubmitLoading={this.state.isSubmitLoading}
        setSubmitLoading={this.setSubmitLoading}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingpostLoginContainer);
