import React, { Component } from "react";
import PropTypes from "prop-types";

import aspire_logo from "images/custom/login/app_logo.png";

import localize from "lang/localize";

const propTypes = {
  language: PropTypes.string
};

class LoginImageRow extends Component {
  render() {
    return (
      <div className="innerblock pure-g">
        <div className="pure-u-5-24 pure-u-sm-8-24 pure-u-md-9-24" />
        <div className="pure-u-14-24 pure-u-sm-8-24 pure-u-md-6-24">
          <img
            src={aspire_logo}
            alt={localize("singpost_aspire_academy_title", this.props.language)}
            className="imgresponsive bottommargin-40"
          />
        </div>
        <div className="pure-u-5-24 pure-u-sm-8-24 pure-u-md-9-24" />
      </div>
    );
  }
}

LoginImageRow.propTypes = propTypes;

export default LoginImageRow;
