import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Router from "router";
import {
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN,
  PROJECT_LOGIN,
  LOGOUT,
  PROJECT_LOGOUT
} from "App/Routes";
import Link from "components/shared/Link/Link";
import localStorageService from "services/localStorageService";
import { setSessionKey, setUser, showAlertWithTimeout } from "actions";
import sessionStorageService from "services/sessionStorageService";
import popupStorage from "services/popupStorage";

import {
  USE_GAMETIZE_APP_LAYOUT,
  ENABLE_LOGIN_HOME,
  ENABLE_MICROSOFT_LOGIN
} from "config";
import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import { POST_LOGOUT, VALIDATE_SESSION } from "services/api";
import localize from "lang/localize";

import {
  MICROSOFT_CLIENT_INSTANCE,
  MICROSOFT_SESSION
} from "services/microsoftServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUser: user => {
      dispatch(setUser(user));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

const propTypes = {
  handleHideMenu: PropTypes.func
};

export class LogoutContainer extends Component {
  handleValidateSessionThenLogout() {
    let data = {
      token: Math.random() /* Dummy parameter so that data will not be null */
    };

    pushApiGenerator(VALIDATE_SESSION, data, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          this.handlePostLogout();
        } else if (this.props.userId && this.props.userId !== res.body.userId) {
          this.handlePostLogout();
        } else {
          this.handleLogout();
        }
      }
    );
  }

  handleLogout() {
    getApiGenerator(POST_LOGOUT, {}, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.handlePostLogout();
      }
    });
  }

  handlePostLogout() {
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    /*
      Theoretically, it makes more sense to make navigation the last action conducted upon logout.
      This is because *LoginContainer will re-route to app's Home page when sessionKey and userId
      still exist. However, we will make it the first action for now. This is because alerts are
      broken (they show, but disappear in a split second) when routing is moved to the last action.
    */

    let redirectUri = LOGIN;

    if (IS_EMBEDDED_PROJECT && this.props.projectId && ENABLE_LOGIN_HOME) {
      redirectUri = PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (IS_EMBEDDED_PROJECT && this.props.projectId) {
      redirectUri = PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      redirectUri = LOGIN_HOME;
    }

    if (ENABLE_MICROSOFT_LOGIN) {
      const hasMicrosoftSession = localStorageService.getItem(
        MICROSOFT_SESSION
      );
      if (hasMicrosoftSession != null) {
        localStorageService.removeItem(MICROSOFT_SESSION);

        const account = MICROSOFT_CLIENT_INSTANCE.getAllAccounts();
        if (account !== null) {
          // There is no silent logout method
          MICROSOFT_CLIENT_INSTANCE.logoutRedirect({
            postLogoutRedirectUri: redirectUri
          });
        }
      }
    }

    Router.navigate(redirectUri);

    this.props.showAlertWithTimeout({
      text: localize("alert_logout_success", this.props.language),
      type: "success"
    });

    localStorageService.saveState({
      user_id: null,
      sessionKey: null
    });
    this.props.setSessionKey(null);
    this.props.setUser({ id: null });
    sessionStorageService.removeItem("loginRedirectUrl");
    sessionStorageService.removeItem("last_project_id");
    sessionStorageService.removeItem(MICROSOFT_SESSION);
    popupStorage.clearAll();
  }

  handleLogoutClick = event => {
    event.preventDefault();

    if (this.props.handleHideMenu) {
      this.props.handleHideMenu();
    }

    this.handleValidateSessionThenLogout();
  };

  render() {
    return (
      <Link
        to={
          this.props.projectId && !USE_GAMETIZE_APP_LAYOUT
            ? PROJECT_LOGOUT.format(this.props.projectId)
            : LOGOUT
        }
        className={this.props.className}
        onClick={e => this.handleLogoutClick(e)}
      >
        {this.props.children}
      </Link>
    );
  }
}

LogoutContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
