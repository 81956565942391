const deviceServices = {
  isMobile: function() {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      /Android/.test(navigator.userAgent)
    );
  },
  isAndroid: function() {
    return /Android/.test(navigator.userAgent);
  },
  isIOS: function() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  },
  isSafari: function() {
    return (
      !!window.navigator.userAgent.match(/WebKit/i) &&
      !window.navigator.userAgent.match(/CriOS/i) &&
      !window.navigator.userAgent.match(/FxiOS/i) &&
      !window.navigator.userAgent.match(/EdgiOS/i)
    );
  },
  isWebView: function() {
    return (
      /Dalvik/.test(navigator.userAgent) && /Android/.test(navigator.userAgent)
    );
  }
};

export default deviceServices;
