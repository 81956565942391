export const ACCEPT_CHALLENGE = "action/accept.json",
  BOOKMARK_ACTION = "action/bookmark.json",
  COLLECT_ITEM = "action/use_item.json",
  GET_BOOKMARKS = "users/{0}/bookmarks.json",
  GET_CHALLENGE = "challenges/{0}.json",
  GET_CHALLENGE_ACTIVTY = "challenges/{0}/claims.json",
  GET_CHALLENGE_COMMENTS = "challenges/{0}/comments.json",
  GET_CHALLENGES = "games/{0}/challenges.json",
  GET_CLAIM_ACTIVTY = "claims/{0}.json",
  GET_CLAIM_COMMENTS = "claims/{0}/comments.json",
  GET_ITEM = "items/{0}.json",
  GET_LEADERBOARD = "bundles/{0}/leaderboard.json",
  GET_LEADERBOARD_OVERVIEW = "bundles/{0}/leaderboard_overview.json",
  GET_TOPIC_LEADERBOARD = "games/{0}/leaderboard.json",
  GET_TOPIC_LEADERBOARD_OVERVIEW = "games/{0}/leaderboard_overview.json",
  GET_NOTIFICATIONS = "notifications.json",
  GET_NOTIFICATIONS_UNREAD = "notifications/unread.json",
  GET_INBOX_UNREAD = "inbox/unread.json",
  GET_PASSWORD_POLICY = "policies/password.json",
  GET_PROJECT_ACTIVTY = "bundles/{0}/claims.json",
  GET_PROJECT_CHALLENGES = "bundles/{0}/challenges.json",
  GET_PROJECT_ITEMS = "bundles/{0}/items.json",
  GET_PROJECT_USERS = "bundles/{0}/users.json",
  GET_PROJECT_BUTTONS = "bundles/{0}/buttons.json",
  GET_PROJECTS_LIST = "masters/{0}/games.json",
  GET_REDEEMED = "users/{0}/items_purchased.json",
  GET_REWARDS = "bundles/{0}/store.json",
  GET_TEAM = "teams/{0}/users.json",
  GET_TEAMS = "bundles/{0}/teams.json",
  GET_TOPIC_COMMENTS = "games/{0}/comments.json",
  GET_COMMENTS_THREAD = "comments/{0}/threads.json",
  GET_TOPICS = "bundles/{0}/games.json",
  GET_USER = "users/{0}.json",
  JOIN_TEAM = "action/join_team.json",
  UNJOIN_TEAM = "action/unjoin_team.json",
  GET_USER_ITEMS = "users/{0}/items.json",
  GET_USER_WIDGET = "users/{0}/widget.json",
  JOIN_TOPIC = "action/join.json",
  LIKE_ACTION = "action/rate.json",
  POST_CLAIM = "action/claim.json",
  DELETE_CLAIM = "action/delete_claim.json",
  DELETE_COMMENT = "action/delete_comment.json",
  DELETE_SUBCOMMENT = "action/delete_comment_thread.json",
  POST_COMMENT = "action/comment.json",
  POST_SUBCOMMENT = "action/comment_thread.json",
  PURCHASE_ITEM = "action/purchase_item.json",
  REGISTER_USER = "register.json",
  UPDATE_USER = "action/update_user.json",
  UPDATE_PASSWORD = "action/update_password.json",
  VALIDATE_PROJECT = "bundles/{0}/validate.json",
  VALIDATE_PROJECT2 =
    "projects/{0}/validate.json" /* API2 version for Project Code in URLs */,
  UNJOIN_PROJECT = "action/unjoin_project.json",
  RESET_TOPIC_ACTIVITY = "action/reset_topic.json",
  VALIDATE_SESSION = "login/validate.json",
  GET_PROJECT_POPUP = "bundles/{0}/alerts.json",
  TOUR_SCREENS = "app/tour_screens.json",
  POST_LOGIN = "login.json",
  POST_LOGIN_GUEST = "login/guest.json",
  POST_LOGOUT = "logout.json",
  /* OAuth logins */
  GET_FACEBOOK_OAUTH = "oauth/facebook.json",
  GET_GOOGLE_OAUTH = "oauth/google.json",
  GET_MICROSOFT_OAUTH = "oauth/microsoft.json",
  GET_OAUTH_SESSION_KEY = "oauth/tokenlogin.json";
