import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import linkifyHtml from "linkify-html";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import VideoDialog from "components/shared/Dialogs/VideoDialog/VideoDialog";
import DeleteCompletionDialogContainer from "components/Project/Activity/DeleteCompletionDialog/DeleteCompletionDialogContainer";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import { CLAIM, CLAIM_ENQUIRY, CLAIM_COMMENTS } from "App/Routes";
import Link from "components/shared/Link/Link";

import {
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_CLAIM_COMMENTS
} from "config";
import localize from "lang/localize";

const propTypes = {
  sessionKey: PropTypes.string,
  id: PropTypes.number.isRequired,
  projectId: PropTypes.number,
  claimUserId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  type: PropTypes.oneOf(["project", "challenge", "claim"]).isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  claimImage: PropTypes.string,
  claimVideo: PropTypes.string,
  claimVideoContainer: PropTypes.string,
  claimVideoThumbnail: PropTypes.string,
  entries: PropTypes.array,
  claimMessage: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  commentNo: PropTypes.number.isRequired,
  ratedLike: PropTypes.bool,
  handleLike: PropTypes.func.isRequired,
  removeClaim: PropTypes.func,
  privateClaim: PropTypes.bool,
  showDeleteCompletionDialog: PropTypes.bool.isRequired,
  handleOpenDeleteCompletionDialog: PropTypes.func.isRequired,
  handleCloseDeleteCompletionDialog: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  handleComments: PropTypes.func.isRequired,
  showLikeLoginDialog: PropTypes.bool.isRequired,
  handleOpenLikeLoginDialog: PropTypes.func.isRequired,
  handleCloseLikeLoginDialog: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
  handleClaimClick: PropTypes.func,
  // video dialogs
  showVideoDialog: PropTypes.bool.isRequired,
  handleOpenVideoDialog: PropTypes.func.isRequired,
  handleCloseVideoDialog: PropTypes.func.isRequired
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT
};

class ClaimResult extends Component {
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = linkifyHtml(text, {
        defaultProtocol: "https",
        class: "link default secondaryLink break-url",
        target: "_blank",
        rel: "external"
      });
    }
    return text;
  }

  renderDeleteCompletionDialog() {
    return (
      <DeleteCompletionDialogContainer
        showModal={this.props.showDeleteCompletionDialog}
        claimId={this.props.id}
        handleCloseDeleteCompletionDialog={
          this.props.handleCloseDeleteCompletionDialog
        }
        removeClaim={this.props.removeClaim}
      />
    );
  }

  renderLikeLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLikeLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLikeLoginDialog}
        type="like"
      />
    );
  }

  renderClaimCommentsButton() {
    if (ENABLE_CLAIM_COMMENTS) {
      return (
        <Link
          to={CLAIM_COMMENTS.format(this.props.id)}
          id="commentButton"
          className="button icon inline-block"
        >
          <span className="comment-icon" />
        </Link>
      );
    } else {
      return null;
    }
  }

  renderMenuDropdownButton() {
    /*
      does not render dropdown button if at least one of the following is true:
      (1) no session key
      (2) private completion, but completion from another user (report completion, instead of delete)
      (3) don't have any dropdown item
    */
    const dropdownItems = this.renderMenuDropdownLink();
    if (
      !this.props.sessionKey ||
      (this.props.privateClaim &&
        this.props.userId !== this.props.claimUserId) ||
      !!!dropdownItems
    ) {
      return null;
    } else {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }
  }

  renderMenuDropdownLink() {
    if (this.props.userId !== this.props.claimUserId && USE_ENQUIRY_PAGE) {
      return (
        <Link to={CLAIM_ENQUIRY.format(this.props.id)}>
          {localize("report_submission", this.props.language)}
        </Link>
      );
    } else if (
      this.props.userId !== this.props.claimUserId &&
      !USE_ENQUIRY_PAGE
    ) {
      return (
        <a
          href={
            "mailto:" +
            this.props.contactEmail +
            "?subject=" +
            (CONTACT_EMAIL_TITLE_DEFAULT
              ? CONTACT_EMAIL_TITLE_DEFAULT
              : "I want to make a report on Gametize (Completion ID: " +
                this.props.id +
                ")")
          }
        >
          {localize("report_submission", this.props.language)}
        </a>
      );
    } else if (
      this.props.challengeTypeId === 1 || // standard/photo
      this.props.challengeTypeId === 5 || // video
      this.props.challengeTypeId === 13 // multi-field
    ) {
      return (
        <MenuItem
          eventKey={1}
          onSelect={this.props.handleOpenDeleteCompletionDialog}
        >
          {localize("delete_submission", this.props.language)}
        </MenuItem>
      );
    } else {
      return null;
    }
  }

  renderVideoContainer() {
    if (this.props.claimVideoContainer) {
      return (
        <Fragment>
          <div
            className="claim-video-container"
            onClick={this.props.handleOpenVideoDialog}
          >
            <div className="claim-video-overlay" />
            {this.renderVideoThumbnail()}
          </div>
          {this.props.showVideoDialog && this.renderVideoDialog()}
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderVideoThumbnail() {
    let claimVideoThumbnail = this.props.claimVideoThumbnail;

    if (this.props.claimVideoThumbnail) {
      return (
        <img className="claim-video" src={claimVideoThumbnail} alt="claim" />
      );
    } else {
      return <div className="claim-video-empty" />;
    }
  }

  renderVideoDialog() {
    return (
      <VideoDialog
        showModal={this.props.showVideoDialog}
        handleCloseVideoDialog={this.props.handleCloseVideoDialog}
        claimVideo={this.props.claimVideo}
        claimVideoContainer={this.props.claimVideoContainer}
      />
    );
  }

  renderStats() {
    if (ENABLE_CLAIM_COMMENTS) {
      return (
        <div className="pure-u-8-24 pure-u-md-10-24 smalltext flex flex-direction-column justify-content-center">
          <div>
            <Link to={CLAIM.format(this.props.id)}>
              <span id="likes">
                {this.props.likeNo.pluralize(
                  localize("like_text", this.props.language),
                  localize("likes_text", this.props.language)
                )}
              </span>
            </Link>
            <br />
            <Link to={CLAIM.format(this.props.id)}>
              <span id="comments">
                {this.props.commentNo.pluralize(
                  localize("comment_text", this.props.language),
                  localize("comments_text", this.props.language)
                )}
              </span>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="smalltext pure-u-8-24 pure-u-md-10-24 smalltext flex flex-direction-column justify-content-center">
          <div>
            <Link to={CLAIM.format(this.props.id)}>
              <span id="likes">
                {this.props.likeNo.pluralize(
                  localize("like_text", this.props.language),
                  localize("likes_text", this.props.language)
                )}
              </span>
            </Link>
          </div>
        </div>
      );
    }
  }

  render() {
    let anchoredCompletionText = this.addAnchors(this.props.claimMessage);
    let claimImage = this.props.claimImage;

    switch (this.props.challengeTypeId) {
      case 1: // standard
      case 5: // video
      case 13: // multi-field
        return (
          <Fragment>
            <div className="pure-g">
              <div className="pure-u-4-24 pure-u-md-2-24" />
              <div className="pure-u-20-24 pure-u-md-22-24">
                {this.renderVideoContainer()}
              </div>
            </div>
            <div className="pure-g bottompadding-5">
              <div className="pure-u-4-24 pure-u-md-2-24" />
              {/*
                Although the below uses <a> instead of <Link>,
                its onClick handler allows it to continue behaving
                as though the link is passed through a SPA router
               */}
              <a
                className="pure-u-20-24 pure-u-md-22-24 link"
                href={CLAIM.format(this.props.id)}
                onClick={this.props.handleClaimClick}
              >
                {this.props.claimImage ? (
                  <img className="claim-photo" src={claimImage} alt="claim" />
                ) : null}
                {this.props.entries // for multi-field
                  ? this.props.entries.map((entry, index) => {
                      let fieldTitle = entry.fieldTitle;
                      let entryEntry = entry.entry;

                      return (
                        <p id="entry" key={index}>
                          <strong>{fieldTitle}</strong>
                          <br />
                          {entryEntry}
                        </p>
                      );
                    })
                  : null}
                {anchoredCompletionText ? ( // for standard
                  <p
                    id="message"
                    dangerouslySetInnerHTML={{ __html: anchoredCompletionText }}
                  />
                ) : null}
              </a>

              <div className="pure-u-4-24 pure-u-md-2-24" />
              {this.renderStats()}
              <div className="pure-u-12-24 textright activity-buttons-container">
                {this.props.isLoggedIn ? (
                  <button
                    id="likeButton"
                    className={
                      "button icon inline" +
                      (this.props.ratedLike ? " liked" : "")
                    }
                    onClick={this.props.handleLike}
                  >
                    <span className="like-icon" />
                  </button>
                ) : (
                  <div className="inline">
                    <button
                      id="loginBeforeLikeButton"
                      className={
                        "button icon inline" +
                        (this.props.ratedLike ? " liked" : "")
                      }
                      onClick={this.props.handleOpenLikeLoginDialog}
                    >
                      <span className="like-icon" />
                    </button>
                    {this.props.showLikeLoginDialog &&
                      this.renderLikeLoginDialog()}
                  </div>
                )}
                {this.renderClaimCommentsButton()}
                {this.renderMenuDropdownButton()}
              </div>
              {this.props.showDeleteCompletionDialog &&
                this.renderDeleteCompletionDialog()}
            </div>
          </Fragment>
        );
      case 2: //quiz
      case 3: //prediction
      case 6: //api
      case 7: //qr
      case 8: //code
      case 9: //key (deprecated)
      case 10: //quiz (repeatable)
      case 11: //survey
      case 12: //invitation (deprecated)
      case 14: //personality quiz
      case 15: //external (deprecated)
        return (
          <Fragment>
            {/*
              Challenge types that don't allow deleting of completions and don't have images
            */}
            <div className="pure-g bottompadding-5">
              <div className="pure-u-4-24 pure-u-md-2-24" />
              {/*
                Although the below uses <a> instead of <Link>,
                its onClick handler allows it to continue behaving
                as though the link is passed through a SPA router
               */}
              <a
                className="pure-u-20-24 pure-u-md-22-24 link"
                href={CLAIM.format(this.props.id)}
                onClick={this.props.handleClaimClick}
              >
                {anchoredCompletionText ? ( // generic
                  <p
                    id="message"
                    dangerouslySetInnerHTML={{ __html: anchoredCompletionText }}
                  />
                ) : null}
              </a>

              <div className="pure-u-4-24 pure-u-md-2-24" />
              {this.renderStats()}
              <div className="pure-u-12-24 textright activity-buttons-container">
                {this.props.isLoggedIn ? (
                  <button
                    id="likeButton"
                    className={
                      "button icon inline" +
                      (this.props.ratedLike ? " liked" : "")
                    }
                    onClick={this.props.handleLike}
                  >
                    <span className="like-icon" />
                  </button>
                ) : (
                  <div className="inline">
                    <button
                      id="loginBeforeLikeButton"
                      className={
                        "button icon inline" +
                        (this.props.ratedLike ? " liked" : "")
                      }
                      onClick={this.props.handleOpenLikeLoginDialog}
                    >
                      <span className="like-icon" />
                    </button>
                    {this.props.showLikeLoginDialog &&
                      this.renderLikeLoginDialog()}
                  </div>
                )}
                {this.renderClaimCommentsButton()}
                {this.renderMenuDropdownButton()}
              </div>
            </div>
          </Fragment>
        );
      default:
        return null;
    }
  }
}

ClaimResult.propTypes = propTypes;
ClaimResult.defaultProps = defaultProps;

export default ClaimResult;
