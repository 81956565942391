import React, { Fragment } from "react";

import "./Login.css";
import { REGISTER, PROJECT_REGISTER } from "App/Routes";
import TextInput from "components/shared/Inputs/TextInput";
import PasswordInput from "../../components/shared/Inputs/PasswordInput";
import Link from "components/shared/Link/Link";
import LoginImageRow from "./LoginImageRow";
import LoginPage from "components/Login/LoginPage";

import {
  ENABLE_REGISTER_PAGES,
  PRIVACY_PAGE_LINK,
  ENABLE_LOGIN_HOME,
  USE_LOGIN_HOME_TEMPLATE_1,
  ENABLE_LOGIN_TOPBAR,
  ENABLE_EMAIL_LOGIN
} from "config";

import localize from "lang/localize";

class SingpostLoginPage extends LoginPage {
  renderLoginFields() {
    if (ENABLE_EMAIL_LOGIN) {
      return (
        <Fragment>
          <TextInput
            id="emailInput"
            value={this.props.email}
            onChange={this.props.handleEmailChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_email_placeholder_text",
              this.props.language
            )}
          />
          <PasswordInput
            id="passwordInput"
            value={this.props.password}
            onChange={this.props.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_password_placeholder_text",
              this.props.language
            )}
          />
          {this.renderResetPasswordOrResendActivation()}
          {this.renderRecaptcha()}
          <div className="pure-g bottommargin">
            <div className="pure-u-1-1 flex justify-content-flexend">
              <button
                id="loginButton"
                className={
                  "button medium" +
                  (!!this.props.isSubmitLoading ? " disabled" : " cta")
                }
                type="submit"
                disabled={!!this.props.isSubmitLoading}
              >
                {!!this.props.isSubmitLoading
                  ? localize(
                      "nav_bar_title_generic_loading",
                      this.props.language
                    )
                  : localize("button_login", this.props.language)}
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderRegisterSection() {
    if (
      !ENABLE_LOGIN_HOME ||
      (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_1)
    ) {
      return (
        <Fragment>
          <br />
          <p className="textcenter">
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
              className="default"
            >
              <strong>
                {localize("login_view_register_start", this.props.language) +
                  " "}
                {localize("login_view_signup_text", this.props.language)}
              </strong>
            </Link>
          </p>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  render() {
    if (
      /* checking type is important: showPrivacyAgreement is initialised as null */
      typeof this.props.showPrivacyAgreement === "boolean" &&
      this.props.showPrivacyAgreement === true
    ) {
      return (
        <div className="login-register-container container verticalpadding">
          {this.renderTopbarlessBackButton()}
          <LoginImageRow language={this.props.language} />
          <div
            className={
              "innerblock pure-g" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
              <h5>
                {localize(
                  "login_view_privacy_policy_updated_title",
                  this.props.language
                )}
              </h5>
              <p>
                {localize("login_agree_to_new_terms_text", this.props.language)}
              </p>
              <div className="bottommargin-30">
                <p className="smalltext">
                  <input
                    type="checkbox"
                    onChange={this.props.handleUpdatePrivacyAgreed}
                    checked={this.props.privacyAgreed}
                  />{" "}
                  {localize(
                    "register_agree_to_gametize_text",
                    this.props.language
                  )}
                  <a
                    className="link default"
                    href={PRIVACY_PAGE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {localize(
                      "register_privacy_policy_text",
                      this.props.language
                    )}
                  </a>
                  .
                </p>
                <p className="smalltext">
                  <input
                    type="checkbox"
                    onChange={this.props.handleUpdateMarketingConsent}
                    checked={this.props.marketingConsent}
                  />{" "}
                  {localize(
                    "register_marketing_communications_text",
                    this.props.language
                  )}
                </p>
              </div>
              <div className="pure-g bottommargin">
                <div className="pure-u-11-24 pure-u-sm-14-24 pure-u-lg-2-3" />
                <div className="pure-u-13-24 pure-u-sm-10-24 pure-u-lg-1-3">
                  <button
                    className={
                      "button fullwidth " +
                      (this.props.privacyAgreed ? "cta" : "inactive")
                    }
                    onClick={this.props.handlePrivacySubmit}
                    disabled={!this.props.privacyAgreed}
                  >
                    {localize("button_quest_continue", this.props.language)}
                  </button>
                </div>
              </div>
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-register-container container verticalpadding">
          {this.renderTopbarlessBackButton()}
          <LoginImageRow />
          <div
            className={
              "innerblock pure-g" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
              <form
                className="pure-form"
                onSubmit={this.handlePreSubmit.bind(this)}
              >
                {this.renderLoginFields()}
                {this.renderGuestLoginSection()}
                {this.renderSSOLoginSection()}
                {ENABLE_REGISTER_PAGES && this.renderRegisterSection()}
              </form>
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
        </div>
      );
    }
  }
}

export default SingpostLoginPage;
