import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import AchievementsSlider from "components/shared/Achievements/AchievementsSlider/AchievementsSlider";

import "library/font-awesome/all.min.css";
import "./ChallengeCompletedModal.css";
import QuizGrid from "./../QuizGrid";

import plus_points from "images/core/challenge/icon_plus_points.png";
import correct_points from "images/core/challenge/icon_quiz_right.png";
import wrong_points from "images/core/challenge/icon_quiz_wrong.png";
import prediction_points from "images/core/challenge/icon_prediction_complete.png";
import icon_information from "images/core/challenge/icon_information.png";

import localize from "lang/localize";

import linkifyHtml from "linkify-html";

const propTypes = {
  challengeCompletedArr: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  goToNextChallenge: PropTypes.func.isRequired,
  goToChallengeAFriend: PropTypes.func.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Display Challenge Feedback Popup
 */
class ChallengeCompletedModal extends Component {
  /**
   * Add anchors to text
   *
   * @param {string} text
   */
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = linkifyHtml(text, {
        defaultProtocol: "https",
        class: "link default break-url",
        target: "_blank"
      });
    }
    return text;
  }

  /**
   * Render the close button for the popup
   *
   * @param {number} lastChallengeCompletedId
   */
  renderCloseButton(lastChallengeCompletedId) {
    /* Wrap button in a <div> to avoid being flex-ed */
    return (
      <div>
        <button
          onClick={() => {
            this.props.handleCloseModal(lastChallengeCompletedId);
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  /**
   * Render points earned
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsWrapper(challenge, challengeTypeId) {
    let pointsExist = challenge.points && challenge.points > 0;

    return (
      <div className="modal-contentblock">
        <div
          className={
            "flex align-items-center" +
            (pointsExist ? " justify-content-space-between" : "")
          }
        >
          <div className="flex align-items-center">
            {this.renderPointsFlavor(challenge, challengeTypeId)}
          </div>
          {this.renderPointsText(challenge.points, pointsExist)}
        </div>
      </div>
    );
  }

  /**
   * Render points earned with flavour (correct/wrong)
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsFlavor(challenge, challengeTypeId) {
    switch (challengeTypeId) {
      case 2:
      case 10: // quiz and repeatable quiz
        if (challenge.correctAnswer) {
          return (
            <Fragment>
              <div className="challenge-completion-image-container flex align-items-center">
                <img
                  src={correct_points}
                  alt={localize(
                    "feedback_quiz_right_text",
                    this.props.language
                  )}
                />
              </div>
              <div className="challenge-completion-container">
                {localize("feedback_quiz_right_text", this.props.language) +
                  this.renderUnlockedTextString(challenge.unlockedChallenge)}
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <div className="challenge-completion-image-container flex align-items-center">
                <img
                  src={wrong_points}
                  alt={localize(
                    "feedback_quiz_wrong_text",
                    this.props.language
                  )}
                />
              </div>
              <div className="challenge-completion-container">
                {localize("feedback_quiz_wrong_text", this.props.language) +
                  this.renderUnlockedTextString(challenge.unlockedChallenge)}
              </div>
            </Fragment>
          );
        }

      case 3: // prediction
        return (
          <Fragment>
            <div className="challenge-completion-image-container flex align-items-center">
              <img
                src={prediction_points}
                alt={localize("feedback_prediction_text", this.props.language)}
              />
            </div>
            <div className="challenge-completion-container">
              {localize("feedback_prediction_text", this.props.language) +
                this.renderUnlockedTextString(challenge.unlockedChallenge)}
            </div>
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <div className="challenge-completion-image-container flex align-items-center">
              <img
                src={plus_points}
                alt={localize("feedback_default_text", this.props.language)}
              />
            </div>
            <div className="challenge-completion-container">
              {localize("feedback_default_text", this.props.language) +
                this.renderUnlockedTextString(challenge.unlockedChallenge)}
            </div>
          </Fragment>
        );
    }
  }

  /**
   * Render unlocked challenge
   *
   * @param {object} unlockedChallenge
   */
  renderUnlockedTextString(unlockedChallenge) {
    if (unlockedChallenge) {
      return (
        " " + localize("feedback_unlock_new_challenge", this.props.language)
      );
    } else {
      /* return empty string; do not return null */
      return "";
    }
  }

  /**
   * Render points noun
   *
   * @param {number} points
   * @param {boolean} pointsExist
   */
  renderPointsText(points, pointsExist) {
    /* points does not exist, or points is 0 */
    if (pointsExist) {
      return (
        <div className="challenge-completion-container norightmargin">
          <strong>{"+" + points.abbreviateNumber()}</strong>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render correct answer
   *
   * @param {object} challenge
   */
  renderCorrectAnswer(challenge) {
    if (
      typeof challenge.correctAnswerTitle === "string" &&
      challenge.correctAnswer === false
    ) {
      if (challenge.gridWidth === undefined || challenge.gridWidth === 0) {
        return this.renderCorrectAnswerText(challenge);
      } else {
        // If options are not available for rendering, use text instead.
        if (challenge.quizOptions !== undefined) {
          return this.renderCorrectAnswerGrid(challenge);
        } else {
          return this.renderCorrectAnswerText(challenge);
        }
      }
    } else {
      return null;
    }
  }

  /**
   * Render correct answer in text
   *
   * @param {object} challenge
   */
  renderCorrectAnswerText(challenge) {
    return (
      <div className="modal-contentblock">
        <div className="flex align-items-center">
          {localize("feedback_right_answer_text", this.props.language).format(
            challenge.correctAnswerTitle
          )}
        </div>
      </div>
    );
  }

  /**
   * Render correct answer grid
   *
   * @param {object} challenge
   */
  renderCorrectAnswerGrid(challenge) {
    return (
      <div className="modal-contentblock">
        <QuizGrid
          gridWidth={challenge.gridWidth}
          quizOptions={challenge.quizOptions}
        />
      </div>
    );
  }

  /**
   * Render completion message
   *
   * @param {object} challenge
   */
  renderCompletionMessage(challenge) {
    let completionMessageAnchored = this.addAnchors(
      challenge.completionMessage
    );

    return (
      <div className="modal-contentblock">
        <div className="flex align-items-center">
          <div className="challenge-completion-image-container flex align-items-center">
            <img src={icon_information} alt={challenge.completionMessage} />
          </div>
          <div
            className="challenge-completion-container responsive-img-description norightmargin"
            dangerouslySetInnerHTML={{ __html: completionMessageAnchored }}
          />
        </div>
      </div>
    );
  }

  /**
   * Render personality quiz result
   * (For personality quiz results only)
   *
   * @param {object} unlockedChallenge
   * @returns
   */
  renderPersonality(challenge) {
    let tag = challenge.tag ? challenge.tag.tag : "";
    let tagImage = challenge.tag ? challenge.tag.image : "";
    let tagDescription = challenge.tag ? challenge.tag.description : "";

    return (
      <div className="modal-contentblock personality-wrapper textcenter">
        {challenge.tag.tag && (
          <div>
            <h3>{localize("tag_result_text", this.props.language) + ": "}</h3>
            <h3>
              <span>{tag}</span>
            </h3>
          </div>
        )}
        {challenge.tag.image && (
          <p>
            <img src={tagImage} alt={tag} />
          </p>
        )}
        {challenge.tag.description && <p>{tagDescription}</p>}
      </div>
    );
  }

  /**
   * Render points icon
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsImg(challenge, challengeTypeId) {
    switch (challengeTypeId) {
      case 2:
      case 10: // quiz and repeatable quiz
        if (challenge.correctAnswer) {
          return <img src={correct_points} alt="" />;
        } else {
          return <img src={wrong_points} alt="" />;
        }

      case 3: // prediction
        return <img src={prediction_points} alt="" />;

      default:
        return <img src={plus_points} alt="" />;
    }
  }

  /**
   * Render achievements earned
   *
   * @param {object} challenge
   */
  renderAchievements(challenge) {
    return (
      <div className="modal-contentblock textcenter">
        <h4>
          {localize("feedback_unlock_start", this.props.language) +
            challenge.items.length +
            " " +
            challenge.items.length.pluralize(
              localize("nav_bar_title_item_profile_text", this.props.language),
              localize("nav_bar_title_items_text", this.props.language)
            ) +
            "!"}
        </h4>
        <div className="achievements-container">
          <AchievementsSlider achievements={challenge.items} />
        </div>
      </div>
    );
  }

  /**
   * Render CTA buttons
   */
  renderMainCTAButtons(
    challenge,
    nextChallengeId,
    lastChallengeCompletedId,
    topicIdNext
  ) {
    if (topicIdNext) {
      /* next topic available */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button fullwidth button-modal-close"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button cta fullwidth nomargin topic-next-button"
                onClick={() => {
                  this.props.goToNextTopic(
                    topicIdNext,
                    lastChallengeCompletedId
                  );
                }}
                id="goToNextTopicButton"
              >
                {localize("button_next_topic", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (nextChallengeId) {
      /* next challenge available */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button fullwidth button-modal-close"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button cta fullwidth nomargin"
                onClick={() => {
                  this.props.goToNextChallenge(
                    nextChallengeId,
                    lastChallengeCompletedId
                  );
                }}
                id="goToNextChallengeButton"
              >
                {localize(
                  "button_feedback_view_next_challenge",
                  this.props.language
                )}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      /* everything else - just view challenge & challenge refer button */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button button-modal-close fullwidth nomargin"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
        </div>
      );
    }
  }

  /**
   * Render "challenge a friend" section
   *
   * @param {object} challenge
   * @param {number} lastChallengeCompletedId
   */
  renderChallengeAFriendButtonContainer(challenge, lastChallengeCompletedId) {
    if (challenge.challengeReferralLink) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-8" />
          <div className="pure-u-3-4">
            <button
              className="button button-modal-close fullwidth"
              onClick={() => {
                this.props.goToChallengeAFriend(
                  challenge.challengeId,
                  lastChallengeCompletedId
                );
              }}
            >
              {localize("challenge_referral_text", this.props.language)}
            </button>
          </div>
          <div className="pure-u-1-8" />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render the view
   */
  render() {
    if (
      this.props.challengeCompletedArr.length > 0 &&
      this.props.challengeCompletedArr[0].challengeCompleted
    ) {
      /* Initialising variables */
      const lastChallengeCompletedId = this.props.challengeCompletedArr[0].id;
      const challenge = this.props.challengeCompletedArr[0].challengeCompleted;
      const challengeTypeId = challenge.challengeTypeId;
      const nextChallengeId = challenge.nextChallengeId;
      const topicIdNext = challenge.topicIdNext;

      return (
        <div>
          <Modal
            isOpen={this.props.showModal}
            contentLabel={localize(
              "feedback_default_text",
              this.props.language
            )}
            onRequestClose={() => {
              this.props.handleCloseModal(lastChallengeCompletedId);
            }}
            shouldCloseOnOverlayClick={false}
            className="modal-content challenge-completed-modal-content"
            overlayClassName="modal-overlay"
          >
            {this.renderCloseButton(lastChallengeCompletedId)}

            <div className="modal-body">
              {this.renderPointsWrapper(challenge, challengeTypeId)}

              {this.renderCorrectAnswer(challenge)}

              {challenge.completionMessage &&
                this.renderCompletionMessage(challenge)}

              {challenge.tag && this.renderPersonality(challenge)}

              {challenge.items &&
                challenge.items.length >= 1 &&
                this.renderAchievements(challenge)}
            </div>

            {this.renderMainCTAButtons(
              challenge,
              nextChallengeId,
              lastChallengeCompletedId,
              topicIdNext
            )}
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}

ChallengeCompletedModal.propTypes = propTypes;

export default ChallengeCompletedModal;
