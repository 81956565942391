import React, { Component } from "react";
import PropTypes from "prop-types";

import { CHALLENGE } from "App/Routes";
import Link from "components/shared/Link/Link";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import StandardChallenge from "./StandardChallenge";
import VideoChallenge from "./VideoChallenge";
import QuizChallenge from "./QuizChallenge";
import FixedChallenge from "./FixedChallenge";
import MultiFieldChallenge from "./MultiFieldChallenge";
import QRChallenge from "./QRChallenge";
import ExternalChallenge from "./ExternalChallenge";

import localize from "lang/localize";

import linkifyHtml from "linkify-html";

const propTypes = {
  sessionKey: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  challengeId: PropTypes.number.isRequired,
  title: PropTypes.string,
  isConfirmationChallenge: PropTypes.bool,
  gridWidth: PropTypes.number,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  videoDurationLimitFormatted: PropTypes.string,
  externalEmbeddable: PropTypes.object,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  challengeMinigameTitle: PropTypes.string,
  language: PropTypes.string
};

class ClaimForm extends Component {
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = linkifyHtml(text, {
        defaultProtocol: "https",
        class: "link default break-url",
        target: "_blank"
      });
    }
    return text;
  }

  switchClaim() {
    switch (this.props.challengeTypeId) {
      case 1: // standard
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <div className="pure-u-1-24" />
                <div className="pure-u-22-24">
                  <StandardChallenge
                    handleSubmit={this.props.postClaim}
                    photoRequired={this.props.photoRequired}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
                <div className="pure-u-1-24" />
              </div>
            </div>
          </React.Fragment>
        );
      case 5: // video
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <div className="pure-u-1-24" />
                <div className="pure-u-22-24">
                  <VideoChallenge
                    handleSubmit={this.props.postClaim}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    videoDurationLimit={this.props.videoDurationLimit}
                    videoDurationLimitFormatted={
                      this.props.videoDurationLimitFormatted
                    }
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
                <div className="pure-u-1-24" />
              </div>
            </div>
          </React.Fragment>
        );
      case 2: // quiz
      case 3: // prediction
      case 10: // quiz-repeat
      case 11: // survey or confirmation
      case 14: // personality quiz
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <div className="pure-u-1-24" />
                <div className="pure-u-22-24">
                  <QuizChallenge
                    isConfirmationChallenge={this.props.isConfirmationChallenge}
                    options={this.props.quizOptions}
                    handleSubmit={this.props.postClaim}
                    multiSelect={this.props.multiSelect}
                    gridWidth={this.props.gridWidth}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
                <div className="pure-u-1-24" />
              </div>
            </div>
          </React.Fragment>
        );
      case 9: // fixed answer
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <div className="pure-u-1-24" />
                <div className="pure-u-22-24">
                  <FixedChallenge
                    handleSubmit={this.props.postClaim}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
                <div className="pure-u-1-24" />
              </div>
            </div>
          </React.Fragment>
        );
      case 13: // multi-field
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <div className="pure-u-1-24" />
                <div className="pure-u-22-24">
                  <MultiFieldChallenge
                    fields={this.props.fields}
                    handleSubmit={this.props.postClaim}
                    photoRequired={this.props.photoRequired}
                    noPhoto={this.props.noPhoto}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
                <div className="pure-u-1-24" />
              </div>
            </div>
          </React.Fragment>
        );
      case 7: // qr code
        return (
          <div className="container verticalpadding" id="claimform">
            <div className="innerblock" id="claim">
              <div className="pure-u-1-24" />
              <div className="pure-u-22-24">
                <QRChallenge
                  handleSubmit={this.props.postQRClaim}
                  isLoggedIn={this.props.isLoggedIn}
                  handleOpenClaimLoginDialog={
                    this.props.handleOpenClaimLoginDialog
                  }
                  showQRModal={this.props.showQRModal}
                  handleOpenQRModal={this.props.handleOpenQRModal}
                  handleCloseQRModal={this.props.handleCloseQRModal}
                  language={this.props.language}
                />
              </div>
              <div className="pure-u-1-24" />
            </div>
          </div>
        );
      case 15: // external
        return (
          <React.Fragment>
            <div className="challenge-page-divider" />
            <div className="container verticalpadding" id="claimform">
              <div className="innerblock" id="claim">
                <button
                  className={
                    "button fullwidth btn-mini-game-start " +
                    (this.props.claimed ? "inactive" : "cta")
                  }
                  onClick={this.props.handleOpenExternalChallengeDialog}
                >
                  {this.props.claimed &&
                    localize("button_minigame_completed", this.props.language)}
                  {!this.props.claimed &&
                    this.props.claimedBefore &&
                    localize("button_minigame_play_again", this.props.language)}
                  {!(this.props.claimed || this.props.claimedBefore) &&
                    localize("button_minigame_play", this.props.language)}
                </button>
              </div>
            </div>
            {this.props.showExternalChallengeDialog && (
              <ExternalChallenge
                sessionKey={this.props.sessionKey}
                challengeId={this.props.challengeId}
                challengeMinigameTitle={this.props.challengeMinigameTitle}
                title={this.props.title}
                externalEmbeddable={this.props.externalEmbeddable}
                showDialog={this.props.showExternalChallengeDialog}
                handleOpenDialog={this.props.handleOpenExternalChallengeDialog}
                handleCloseDialog={
                  this.props.handleCloseExternalChallengeDialog
                }
              />
            )}
          </React.Fragment>
        );
      case 4: // Flashcard
        if (this.props.nextChallengeId) {
          return (
            <React.Fragment>
              <div className="challenge-page-divider" />
              <div className="container verticalpadding" id="claimform">
                <div className="innerblock" id="claim">
                  <div className="pure-u-8-24" />
                  <div className="pure-u-8-24">
                    <Link
                      className="button cta"
                      to={CHALLENGE.format(this.props.nextChallengeId)}
                    >
                      {localize(
                        "button_feedback_view_next_challenge",
                        this.props.language
                      )}
                    </Link>
                  </div>
                  <div className="pure-u-8-24" />
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  }

  renderCompletionMessage(completion_message) {
    let completionMessageAnchored = this.addAnchors(completion_message);

    return (
      <div dangerouslySetInnerHTML={{ __html: completionMessageAnchored }} />
    );
  }

  renderNotLoggedIn() {
    // add challenges to list which has challenge previews without logging in
    let filteredChallengeArr = [];
    filteredChallengeArr.push(1); // standard/photo
    filteredChallengeArr.push(2); // quiz
    filteredChallengeArr.push(3); // prediction
    filteredChallengeArr.push(4); // flashcard
    filteredChallengeArr.push(5); // video
    filteredChallengeArr.push(7); // QR
    filteredChallengeArr.push(9); // passcode/fixed answer
    filteredChallengeArr.push(10); // repeatable quiz
    filteredChallengeArr.push(11); // poll
    filteredChallengeArr.push(13); // multi-field/custom form
    filteredChallengeArr.push(14); // personality quiz
    filteredChallengeArr.push(15); // external

    // checks if not logged in, and if challenge type id is not part of filtered challenge array list
    if (
      !this.props.isLoggedIn &&
      filteredChallengeArr.indexOf(this.props.challengeTypeId) === -1
    ) {
      return (
        <React.Fragment>
          <div className="challenge-page-divider" />
          <div className="container verticalpadding" id="claimform">
            <div className="innerblock" id="notLoggedIn">
              <div className="pure-u-2-24" />
              <div className="pure-u-20-24">
                <NotLoggedInContainer
                  showLogin={false}
                  message={localize(
                    "require_login_complete_challenge",
                    this.props.language
                  )}
                  language={this.props.language}
                />
              </div>
              <div className="pure-u-2-24" />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderClaimedBefore() {
    if (this.props.claimedBefore && this.props.completionMessage) {
      return (
        <React.Fragment>
          <div className="challenge-page-divider" />
          <div className="container verticalpadding">
            <div
              className="innerblock responsive-img-description"
              id="completed"
            >
              <div className="pure-u-2-24" />
              <div className="pure-u-20-24">
                <h5>
                  {localize("feedback_default_text", this.props.language)}
                </h5>
                {this.renderCompletionMessage(this.props.completionMessage)}
              </div>
              <div className="pure-u-2-24" />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderExpired() {
    if (this.props.expired && !this.props.claimed) {
      return (
        <React.Fragment>
          <div className="challenge-page-divider" />
          <div className="container verticalpadding">
            <div className="innerblock" id="expired">
              <div className="pure-u-2-24" />
              <div className="pure-u-20-24">
                {localize(
                  "challenge_profile_expired_text",
                  this.props.language
                )}
              </div>
              <div className="pure-u-2-24" />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderAdminClaim() {
    if (this.props.adminToClaim && !this.props.claimed) {
      return (
        <React.Fragment>
          <div className="challenge-page-divider" />
          <div className="container verticalpadding">
            <div className="innerblock" id="adminCompletion">
              <div className="pure-u-2-24" />
              <div className="pure-u-20-24">
                {localize("only_admin_can_claim_text", this.props.language)}
              </div>
              <div className="pure-u-2-24" />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <>
        {((!this.props.claimed &&
          this.props.isLoggedIn &&
          !this.props.expired &&
          !this.props.adminToClaim) ||
          this.props.challengeTypeId === 15) &&
          this.switchClaim()}
        {this.renderNotLoggedIn()}
        {this.renderClaimedBefore()}
        {this.renderExpired()}
        {this.renderAdminClaim()}
      </>
    );
  }
}

ClaimForm.propTypes = propTypes;

export default ClaimForm;
