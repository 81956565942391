import React, { Component } from "react";
import PropTypes from "prop-types";

import { configureAnchors } from "react-scrollable-anchor";
import ScrollableAnchor from "react-scrollable-anchor";
import Dotdotdot from "react-dotdotdot";
import linkifyHtml from "linkify-html";
import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import { CHALLENGE, CHALLENGE_ENQUIRY, CHALLENGE_A_FRIEND } from "App/Routes";
import locked_challenge from "images/core/placeholders/placeholder_item_locked.png";
import Link from "components/shared/Link/Link";
import "./ChallengeCard.css";

import {
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_CHALLENGE_BOOKMARK_BUTTON,
  ENABLE_CHALLENGE_LIKES
} from "config";
import localize from "lang/localize";

const propTypes = {
  sessionKey: PropTypes.string,
  id: PropTypes.number.isRequired,
  unlocked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  challengeType: PropTypes.string.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  isFlashcard: PropTypes.bool.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  challengeReferralLink: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  completedNo: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  handleLike: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func,
  handleOpenLikeLoginDialog: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
  handleChallengeTitleClick: PropTypes.func
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT
};

/*
  Offset accounts for topbar (50px), and half of challenge icon with buffer (~25px).
  Scroll duration at 1 ms more or less removes the animation
*/
configureAnchors({ offset: -75, scrollDuration: 1 });

class ChallengeCard extends Component {
  setChallengeTypeClass() {
    if (this.props.isConfirmationChallenge) {
      return "confirmation";
    } else {
      return this.props.challengeType;
    }
  }

  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = linkifyHtml(text, {
        defaultProtocol: "https",
        class: "link default secondaryLink break-url",
        target: "_blank",
        rel: "external"
      });
    }
    return text;
  }

  renderCard(props) {
    let anchoredTitle = this.addAnchors(this.props.title);
    let img = this.props.img;

    return (
      <ScrollableAnchor id={"challenge" + this.props.id}>
        <div
          className="challenge-card lighttext box-sizing-border-box-all"
          style={{
            backgroundImage:
              "linear-gradient(rgba(20,20,20,0.3),rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0),rgba(20,20,20,0.3)), url(" +
              img +
              ")"
          }}
        >
          <Link
            className="challenge-card-link-overlay"
            to={CHALLENGE.format(this.props.id)}
          />
          <Link className="icons-wrap" to={CHALLENGE.format(this.props.id)}>
            <div className="icons">
              <div
                className={"challengeicon icon " + this.setChallengeTypeClass()}
              />
              {this.props.points !== 0 ? (
                <div className="challengeicon">
                  <div className="points">
                    +{this.props.points.abbreviateNumber()}
                  </div>
                </div>
              ) : null}
            </div>
          </Link>
          {/*
          Although the below uses <a> instead of <Link>,
          its onClick handler allows it to continue behaving
          as though the link is passed through a SPA router
          */}
          <a
            className="challengecard-description-wrap link"
            href={CHALLENGE.format(props.id)}
            onClick={this.props.handleChallengeTitleClick}
          >
            <div className="challengecard-description">
              <Dotdotdot clamp={3}>
                <span dangerouslySetInnerHTML={{ __html: anchoredTitle }} />
              </Dotdotdot>
            </div>
          </a>
          <div className="pure-u-g challengecard-bottom">
            <div className="pure-u-8-24 stats-wrap">{this.renderStats()}</div>
            <div className="pure-u-1-24" />
            <div className="pure-u-15-24 button-wrap">
              {this.renderButtons()}
            </div>
          </div>
        </div>
      </ScrollableAnchor>
    );
  }

  renderStats() {
    if (this.props.isFlashcard && !ENABLE_CHALLENGE_LIKES) {
      return null;
    } else if (this.props.isFlashcard) {
      return (
        <span id="flashcard-stats" className="stats-text">
          {this.props.likeNo.pluralize(
            localize("like_text", this.props.language),
            localize("likes_text", this.props.language)
          )}
        </span>
      );
    } else {
      return (
        <span id="challenge-stats" className="stats-text">
          {localize("challenge_completed_text", this.props.language).format(
            this.props.completedNo
          )}
        </span>
      );
    }
  }

  renderActionButton() {
    if (this.props.isExpired) {
      return localize(
        "button_challenge_challenge_expired",
        this.props.language
      );
    } else if (this.props.isCompleted) {
      return localize(
        "button_challenge_challenge_completed",
        this.props.language
      );
    } else {
      return localize("button_challenge_do_it", this.props.language);
    }
  }

  renderButtons() {
    if (this.props.isFlashcard) {
      return this.renderFlashcardButtons();
    } else {
      return this.renderChallengeButtons();
    }
  }

  renderFlashcardButtons() {
    return (
      <span id="flashcard-buttons">
        {this.renderBookmarkButton()}
        {this.renderLikeButton()}
        {this.renderMenuDropdownButton()}
      </span>
    );
  }

  renderChallengeButtons() {
    return (
      <span id="challenge-buttons">
        <div>
          {this.renderBookmarkButton()}
          {this.renderMenuDropdownButton()}
        </div>
        <div>
          <button
            className={
              "button medium inline button-challenge-card" +
              (this.props.isCompleted || this.props.isExpired
                ? " inactive"
                : " cta")
            }
          >
            {this.renderActionButton()}
          </button>
        </div>
      </span>
    );
  }

  renderBookmarkButton() {
    if (!ENABLE_CHALLENGE_BOOKMARK_BUTTON) {
      return null;
    } else if (this.props.isLoggedIn) {
      return (
        <button
          className={
            "button icon inline" +
            (this.props.isBookmarked ? " bookmarked" : "")
          }
          onClick={this.props.handleBookmark}
          id="bookmarkButton"
        >
          <span className="bookmark-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            className={
              "button icon inline" +
              (this.props.isBookmarked ? " bookmarked" : "")
            }
            onClick={this.handleOpenBookmarkLoginDialog.bind(this)}
            id="loginBeforeBookmarkButton"
          >
            <span className="bookmark-icon" />
          </button>
          {this.props.showBookmarkLoginDialog &&
            this.renderBookmarkLoginDialog()}
        </div>
      );
    }
  }

  renderLikeButton() {
    if (!ENABLE_CHALLENGE_LIKES) {
      return null;
    } else if (this.props.isLoggedIn) {
      return (
        <button
          className={
            "button icon inline" + (this.props.isLiked ? " liked" : "")
          }
          onClick={this.props.handleLike}
          id="likeButton"
        >
          <span className="like-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            className={
              "button icon inline" + (this.props.isLiked ? " liked" : "")
            }
            onClick={this.handleOpenLikeLoginDialog.bind(this)}
            id="loginBeforeLikeButton"
          >
            <span className="like-icon" />
          </button>
          {this.props.showLikeLoginDialog && this.renderLikeLoginDialog()}
        </div>
      );
    }
  }

  renderMenuDropdownButton() {
    if (this.props.sessionKey) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.challengeReferralLink ? (
                <Link to={CHALLENGE_A_FRIEND.format(this.props.id)}>
                  {localize("challenge_referral_text", this.props.language)}
                </Link>
              ) : null}
              {USE_ENQUIRY_PAGE ? (
                <Link to={CHALLENGE_ENQUIRY.format(this.props.id)}>
                  {localize("report_challenge", this.props.language)}
                </Link>
              ) : (
                <a
                  href={
                    "mailto:" +
                    this.props.contactEmail +
                    "?subject=" +
                    (CONTACT_EMAIL_TITLE_DEFAULT
                      ? CONTACT_EMAIL_TITLE_DEFAULT
                      : "I want to make a report on Gametize (Challenge ID: " +
                        this.props.id +
                        ")")
                  }
                >
                  {localize("report_challenge", this.props.language)}
                </a>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  renderLockedCard() {
    return (
      <div className="challenge-card box-sizing-border-box-all locked lighttext">
        <div className="locked-img flex justify-content-center align-items-center">
          <img
            src={locked_challenge}
            alt={localize("button_quest_locked", this.props.language)}
          />
        </div>
      </div>
    );
  }

  handleOpenBookmarkLoginDialog(event) {
    event.preventDefault();

    this.props.handleOpenBookmarkLoginDialog();
  }

  handleOpenLikeLoginDialog(event) {
    event.preventDefault();

    this.props.handleOpenLikeLoginDialog();
  }

  render() {
    if (this.props.unlocked) {
      return this.renderCard(this.props);
    } else {
      return this.renderLockedCard();
    }
  }
}

ChallengeCard.propTypes = propTypes;
ChallengeCard.defaultProps = defaultProps;

export default ChallengeCard;
