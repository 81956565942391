import React from "react";
import { connect } from "react-redux";

import {
  LoginHomeContainer,
  mapStateToProps,
  mapDispatchToProps
} from "components/Login/LoginHomeContainer";
import SingpostLoginHomePage from "./LoginHomePage";

export class SingpostLoginHomeContainer extends LoginHomeContainer {
  render() {
    return (
      <SingpostLoginHomePage
        projectId={this.props.projectId}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
        responseMicrosoft={this.responseMicrosoft}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingpostLoginHomeContainer);
