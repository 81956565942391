import React from "react";

import {
  LOGIN,
  PROJECT_LOGIN,
  SSO_LOGIN_SUCCESS,
  PROJECT_SSO_LOGIN_SUCCESS
} from "App/Routes";
import app_logo from "images/custom/login/app_logo.png";
import Link from "components/shared/Link/Link";
import LoginHomePage from "components/Login/LoginHomePage";

import {
  ENABLE_SSO_LOGIN,
  ENABLE_OAUTH_LOGIN,
  SSO_LOGIN_LINK,
  PLAYER_URL
} from "config";
import urlServices from "services/urlServices";
import localize from "lang/localize";

class SingpostLoginHomePage extends LoginHomePage {
  /* Custom: Singpost SSO Login button */
  renderSSOLoginButtonContainer() {
    if (ENABLE_OAUTH_LOGIN && ENABLE_SSO_LOGIN) {
      return (
        <div className="pure-g">
          <a
            className="button fullwidth cta"
            href={
              SSO_LOGIN_LINK +
              PLAYER_URL +
              urlServices.removeFirstSlash(
                this.props.projectId
                  ? PROJECT_SSO_LOGIN_SUCCESS.format(this.props.projectId)
                  : SSO_LOGIN_SUCCESS
              )
            }
          >
            {localize("singpost_sso_login_button_text", this.props.language)}
          </a>
        </div>
      );
    } else {
      return null;
    }
  }

  renderImageContainer() {
    return (
      <div className="innerblock pure-g login-logo">
        <div className="pure-u-5-24 pure-u-sm-8-24 pure-u-md-9-24" />
        <div className="pure-u-14-24 pure-u-sm-8-24 pure-u-md-6-24">
          <img
            src={app_logo}
            alt={localize("singpost_aspire_academy_title", this.props.language)}
            className="imgresponsive bottommargin-40"
          />
        </div>
        <div className="pure-u-5-24 pure-u-sm-8-24 pure-u-md-9-24" />
      </div>
    );
  }

  render() {
    return (
      <div className="login-register-container container verticalpadding">
        {this.renderImageContainer()}
        <div className="innerblock pure-g">
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2 login-panel">
            <div className="pure-g">
              <Link
                className="button fullwidth cta"
                to={
                  this.props.projectId
                    ? PROJECT_LOGIN.format(this.props.projectId)
                    : LOGIN
                }
              >
                {localize("singpost_login_button_text", this.props.language)}
              </Link>
            </div>
            {this.renderSSOLoginButtonContainer()}
          </div>
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
        </div>
      </div>
    );
  }
}

export default SingpostLoginHomePage;
