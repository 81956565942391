import React, { Component } from "react";
import PropTypes from "prop-types";
import Achievement from "./Achievement";

import localize from "lang/localize";

const propTypes = {
  items: PropTypes.array.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  board: PropTypes.oneOf(["individual", "team"]).isRequired,
  teamAvailable: PropTypes.bool.isRequired,
  handleToggleBoard: PropTypes.func.isRequired
};

class AchievementsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.className += " achievementsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.classList.remove("achievementsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderBoardNav() {
    return (
      <nav className="nav-buttons">
        <button
          id="team"
          className={
            "button " + (this.props.board === "team" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            <i className="fas fa-users rightmargin-10"></i>
            {localize("icon_leaderboard_mode_team", this.props.language)}
          </span>
        </button>
        <button
          id="individual"
          className={
            "button " +
            (this.props.board === "individual" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            <i className="fas fa-user rightmargin-10"></i>
            {localize("icon_leaderboard_mode_user", this.props.language)}
          </span>
        </button>
      </nav>
    );
  }

  renderItems() {
    const items = this.props.items;
    return items.map((item, index) => (
      <Achievement
        key={item.id}
        id={item.id}
        title={item.name}
        img={item.imageSmall}
        earned={item.earned}
        language={this.props.language}
        projectId={this.props.projectId}
      />
    ));
  }

  render() {
    return (
      <React.Fragment>
        {/*
        If teams are available, show Team/Player as tabs.
        Otherwise, show Time (All-Time / Monthly / Daily).
      */}
        {this.props.teamAvailable && this.renderBoardNav()}
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-md-16-24 automargin">
              {this.renderItems()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AchievementsPage.propTypes = propTypes;

export default AchievementsPage;
