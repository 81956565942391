import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ResetActivityDialogContainer from "components/shared/Dialogs/ResetActivityDialog/ResetActivityDialogContainer";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import Link from "components/shared/Link/Link";
import placeholder_topic from "images/core/placeholders/placeholder_topic.jpg";
import "./TopicCard.css";

import { TOPIC } from "App/Routes";
import localize from "lang/localize";

import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import linkifyHtml from "linkify-html";
import Dotdotdot from "react-dotdotdot";
import { configureAnchors } from "react-scrollable-anchor";
import ScrollableAnchor from "react-scrollable-anchor";

const propTypes = {
  sessionKey: PropTypes.string,
  projectId: PropTypes.number,
  isMobile: PropTypes.bool,
  allowReset: PropTypes.bool,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  totalChallenges: PropTypes.number.isRequired,
  completedChallenges: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  started: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  language: PropTypes.string
};

const defaultProps = {
  isMobile: false,
  allowReset: false,
  img: placeholder_topic,
  projectId: null
};

/*
  Offset accounts for topbar (50px), and 10px buffer.
  Scroll duration at 1 ms more or less removes the animation
*/
configureAnchors({ offset: -60, scrollDuration: 1 });

class TopicCard extends Component {
  constructor() {
    super();
    this.state = {
      showResetActivityDialog: false
    };

    this.handleOpenResetActivityDialog = this.handleOpenResetActivityDialog.bind(
      this
    );
    this.handleCloseResetActivityDialog = this.handleCloseResetActivityDialog.bind(
      this
    );
  }

  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = linkifyHtml(text, {
        defaultProtocol: "https",
        class: "link default break-url",
        target: "_blank",
        rel: "external"
      });
    }
    return text;
  }

  getActionButtonText() {
    if (this.props.completed && this.props.totalChallenges !== 0) {
      return localize("button_quest_completed", this.props.language);
    } else if (!this.props.started) {
      return localize("button_quest_start", this.props.language);
    } else {
      return localize("button_quest_continue", this.props.language);
    }
  }

  renderActionButton() {
    if (this.props.locked) {
      return (
        <div className="card-locked">
          <strong className="card-action-text lighttext">
            {localize("button_quest_locked", this.props.language)}
          </strong>
        </div>
      );
    } else if (this.props.expired) {
      return (
        <div className="card-locked">
          <strong className="card-action-text lighttext">
            {localize("button_quest_expired", this.props.language)}
          </strong>
        </div>
      );
    } else {
      return (
        <Link to={TOPIC.format(this.props.id)}>
          <div className="card-action">
            <strong className="card-action-text lighttext">
              {this.getActionButtonText()}
            </strong>
          </div>
        </Link>
      );
    }
  }

  renderDropdownButton() {
    if (this.props.allowReset && this.props.sessionKey) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
              this.handleOpenResetActivityDialog();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem eventKey={1} onSelect={(eventKey, event) => {}}>
                {localize("reset_topic", this.props.language)}
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  handleOpenResetActivityDialog() {
    this.setState({
      showResetActivityDialog: true
    });
  }

  handleCloseResetActivityDialog() {
    this.setState({
      showResetActivityDialog: false
    });
  }

  renderResetActivityDialog() {
    return (
      <ResetActivityDialogContainer
        context="project"
        showDialog={this.state.showResetActivityDialog}
        handleCloseDialog={this.handleCloseResetActivityDialog}
        topicTitle={this.props.title || ""}
        topicId={this.props.id}
        projectId={this.props.projectId}
      />
    );
  }

  render() {
    let img = this.props.img;
    let title = this.props.title;

    let topicCardImage = (
      <div
        className="card-img"
        style={{
          backgroundColor: "#fff",
          backgroundImage:
            "linear-gradient(rgba(20,20,20,0.3),rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0),rgba(20,20,20,0.3)), url(" +
            img +
            ")"
        }}
      >
        <div className="card-title-wrap">
          <div className="card-title-container">
            <Dotdotdot clamp={3}>{title}</Dotdotdot>
          </div>
        </div>
        {this.renderDropdownButton()}
        <ProgressBar
          completed={this.props.completedChallenges}
          total={this.props.totalChallenges}
          language={this.props.language}
        />
      </div>
    );

    let anchoredTopicDescription = this.addAnchors(this.props.description);

    let topicCard = (
      <div className="topic-card card">
        {!this.props.locked && !this.props.expired ? (
          <Link to={TOPIC.format(this.props.id)}>{topicCardImage}</Link>
        ) : (
          topicCardImage
        )}
        <div className="card-scroll">
          <p
            className="card-desc"
            dangerouslySetInnerHTML={{ __html: anchoredTopicDescription }}
          />
        </div>
        {this.renderActionButton()}
      </div>
    );

    /*
      Scrollable anchors inserted only to desktop topic cards.
      This is because mobile and desktop topic cards are rendered
      in the Project component (with either being on display: none
      depending on resolution. Having 2 of the same anchor names to
      search for confuses the ScrollableAnchor library
    */
    if (!this.props.isMobile) {
      return (
        <Fragment>
          {/* Empty span just so we can stack scrollable anchors */}
          <ScrollableAnchor id={"resettedtopic" + this.props.id}>
            <span></span>
          </ScrollableAnchor>
          <ScrollableAnchor id={"topic" + this.props.id}>
            {topicCard}
          </ScrollableAnchor>
          {this.renderResetActivityDialog()}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {topicCard}
          {this.renderResetActivityDialog()}
        </Fragment>
      );
    }
  }
}

TopicCard.propTypes = propTypes;
TopicCard.defaultProps = defaultProps;

export default TopicCard;
