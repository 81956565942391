import React, { Component } from "react";
import PropTypes from "prop-types";

import "./CollectRedeemedDialog.css";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showCollectRedeemedDialog: PropTypes.bool.isRequired,
  itemTitle: PropTypes.string,
  itemId: PropTypes.number.isRequired,
  itemImg: PropTypes.string.isRequired,
  itemDescriptionAfterRedeem: PropTypes.string,
  itemDescriptionAfterCollect: PropTypes.string,
  itemOption: PropTypes.string,
  itemUsedAtFormatted: PropTypes.string,
  itemCode: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  handleCloseCollectRedeemedDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class CollectRedeemedDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showCollectRedeemedDialog,
      showAfterCollectDialog: false
    };
  }

  toggleShowAfterCollectDialog() {
    this.setState({
      showAfterCollectDialog: !this.state.showAfterCollectDialog
    });
  }

  async copyToClipboard(text) {
    try {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = text;
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.props.showAlertWithTimeout({
        text: localize("clipboard_alert_success", this.props.language),
        type: "success"
      });
    } catch (err) {
      this.props.showAlertWithTimeout({
        text: localize("clipboard_alert_failure", this.props.language).format(
          err
        ),
        type: "error"
      });
    }
  }

  renderBeforeCollectDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5 className="dialog-title">{this.props.itemTitle}</h5>
          <button
            onClick={this.props.handleCloseCollectRedeemedDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <img className="imgresponsive" src={this.props.itemImg} alt="" />
          {this.props.itemDescriptionAfterRedeem && (
            <div
              className="bottommargin-10"
              dangerouslySetInnerHTML={{
                __html: this.props.itemDescriptionAfterRedeem
              }}
            />
          )}
          {this.props.itemDescriptionAfterCollect && (
            <div
              className="bottommargin-10"
              dangerouslySetInnerHTML={{
                __html: this.props.itemDescriptionAfterCollect
              }}
            />
          )}
          {this.props.itemOption && (
            <p className="topmargin-20">
              {localize("reward_selected", this.props.language).format(
                this.props.itemOption
              )}{" "}
            </p>
          )}
          {this.props.itemCode && (
            <div className="collect-code">
              <p>
                {" "}
                {localize("reward_code", this.props.language).format(
                  this.props.itemCode
                )}{" "}
              </p>
              <i
                onClick={() => this.copyToClipboard(this.props.itemCode)}
                className="fas fa-clipboard-list"
              ></i>
            </div>
          )}
          {this.props.itemType === "code" && !this.props.itemUsedAtFormatted && (
            <div className="info-text">
              <i className="fas fa-info-circle"></i>
              <p>{localize("use_item_code_text", this.props.language)}</p>
            </div>
          )}
          {!this.props.itemUsedAtFormatted && (
            <div className="info-text">
              <i className="fas fa-info-circle"></i>
              <p>
                {localize("use_item_message_text", this.props.language).format(
                  this.props.itemTitle
                )}
              </p>
            </div>
          )}
        </div>
        <div className="dialog-footer-button textcenter horizontalpadding-10 verticalpadding-10">
          {!this.props.itemUsedAtFormatted ? (
            <button
              className="button inline cta fullwidth"
              onClick={e => {
                e.preventDefault();
                this.props.handleClick(e, this.props.itemId);
                this.toggleShowAfterCollectDialog();
              }}
            >
              {localize("button_reward_collect", this.props.language)} '
              {this.props.itemTitle}'
            </button>
          ) : (
            <button className="button inline inactive fullwidth">
              {localize("item_used_at_text", this.props.language)}:{" "}
              {this.props.itemUsedAtFormatted}
            </button>
          )}
        </div>
      </div>
    );
  }

  renderAfterCollectDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5 className="dialog-title">
            {localize("item_used_at_text", this.props.language)}!
          </h5>
          <button
            onClick={this.props.handleCloseCollectRedeemedDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <img className="imgresponsive" src={this.props.itemImg} alt="" />
          {this.props.itemDescriptionAfterCollect && (
            <div
              className="bottommargin-10"
              dangerouslySetInnerHTML={{
                __html: this.props.itemDescriptionAfterCollect
              }}
            />
          )}
          {this.props.itemCode && (
            <div className="collect-code">
              <p>
                {" "}
                {localize("reward_code", this.props.language).format(
                  this.props.itemCode
                )}{" "}
              </p>
              <i
                onClick={() => this.copyToClipboard(this.props.itemCode)}
                className="fas fa-clipboard-list"
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel={localize(
          "nav_bar_title_inventory_item_profile_text",
          this.props.language
        )}
        onRequestClose={this.props.handleCloseCollectRedeemedDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog collect-redeemed-dialog"
        overlayClassName="dialog-overlay"
      >
        {this.state.showAfterCollectDialog
          ? this.renderAfterCollectDialog()
          : this.renderBeforeCollectDialog()}
      </Modal>
    );
  }
}

CollectRedeemedDialog.propTypes = propTypes;

export default CollectRedeemedDialog;
